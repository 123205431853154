import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Zahlen, Abkürzungen und Gleichungen können für Screenreader-Nutzer:innen eine Herausforderung darstellen.
Mit der CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`speak-as`}</InlineCode>{` kannst du bestimmen, wie Text vorgelesen werden soll.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAGaplOaY5//xAAaEAEAAwADAAAAAAAAAAAAAAACAAEDERIi/9oACAEBAAEFAsSVDn0mnhrOuGrLStL/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIRIRJR/9oACAEBAAY/An0PunhRCs2hzXpbP//EABoQAQADAQEBAAAAAAAAAAAAAAEAESExQZH/2gAIAQEAAT8hY+BL4saAZsHOkruAMeygy7FJecjruz//2gAMAwEAAgADAAAAEEjf/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EEAMn//EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAgEBPxAdn//EAB0QAQADAAIDAQAAAAAAAAAAAAEAESExQVFxgZH/2gAIAQEAAT8Q6eAANKrVwBwtA7cPLkGiaFnIPUKapluLzf5MkQFJRx6wj2C20UfJ/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Frau spricht in ein Megaphon",
          "title": "Eine Frau spricht in ein Megaphon",
          "src": "/static/2cf1b1bd6dc5e1242769dab7de8c837c/e5166/pexels-edmond-dantes-megaphone.jpg",
          "srcSet": ["/static/2cf1b1bd6dc5e1242769dab7de8c837c/f93b5/pexels-edmond-dantes-megaphone.jpg 300w", "/static/2cf1b1bd6dc5e1242769dab7de8c837c/b4294/pexels-edmond-dantes-megaphone.jpg 600w", "/static/2cf1b1bd6dc5e1242769dab7de8c837c/e5166/pexels-edmond-dantes-megaphone.jpg 1200w", "/static/2cf1b1bd6dc5e1242769dab7de8c837c/b17f8/pexels-edmond-dantes-megaphone.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Edmond Dantès / pexels.com`}</em></p>
    <h2>{`Buchstabieren oder nicht buchstabieren, das ist hier die Frage`}</h2>
    <p>{`Vor einigen Tagen habe ich einen Kunden aus der Bankenbranche beraten. Sie möchten ihre Website für
Screenreader-Nutzer:innen optimieren. Eine Frage lautete: Wie können wir den Screenreader dazu bringen,
eine Zahl immer Ziffer für Ziffer vorzulesen? Zum Beispiel eine Kontonummer mit 12 oder mehr Stellen?`}</p>
    <p>{`In vielen Fällen sagen Screenreader statt der einzelnen Ziffern die ganze Zahl an. Beispielsweise wird 9100 als
„neuntausend einhundert“ anstelle von „neun eins null null“ vorgelesen. Ähnliche Probleme können bei Abkürzungen
oder Gleichungen auftreten. Es läuft alles auf die
`}<a parentName="p" {...{
        "href": "https://www.deque.com/blog/dont-screen-readers-read-whats-screen-part-1-punctuation-typographic-symbols/"
      }}>{`inhärente Heuristik des Screenreaders`}</a>{`
hinaus. Diese bestimmt, wie gewisse Abfolgen von Buchstaben und Satzzeichen vorgelesen werden.`}</p>
    <p>{`Wäre es nicht großartig, wenn wir dem Screenreader einfach sagen könnten, wie er bestimmte Inhalte lesen soll?
Die Antwort lautet: Ja, das geht! Aber die Browserunterstützung ist leider schlecht.`}</p>
    <h2>{`Die CSS-Eigenschaft `}<span lang="en">{`speak-as`}</span></h2>
    <p>{`Das `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-speech-1/"
      }}><span lang="en">{`CSS Speech Module`}</span></a>{` definiert die
Eigenschaft `}<InlineCode mdxType="InlineCode">{`speak-as`}</InlineCode>{`. Sie bestimmt, auf welche Weise Text akustisch wiedergegeben wird,
basierend auf einer vordefinierten Liste von Möglichkeiten:`}</p>
    <ul>
      <li parentName="ul">
        <strong lang="en">normal</strong>: Verwendet die sprachabhängigen Ausspracheregeln des Browsers (Standard).
      </li>
      <li parentName="ul">
        <strong lang="en">spell-out</strong>: Weist den Browser an, den Text zu buchstabieren (nützlich für Akronyme und Abkürzungen).
      </li>
      <li parentName="ul">
        <strong lang="en">digits</strong>: Zahlen werden Ziffer für Ziffer vorgelesen. Zum Beispiel: 9100 als „neun eins null null“.
      </li>
      <li parentName="ul">
        <strong lang="en">literal-punctuation</strong>: Satzzeichen (wie Semikolons, Klammern usw.) werden vorgelesen anstatt sie wie Pausen zu behandeln.
      </li>
      <li parentName="ul">
        <strong lang="en">no-punctuation</strong>: Satzzeichen werden ignoriert.
      </li>
    </ul>
    <p>{`Mithilfe dieser CSS-Eigenschaft könnten wir etwa eine Klasse für Kontonummern wie folgt definieren:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.bank-account-number {
    speak-as: digits;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Browser- und Screenreader-Unterstützung`}</h2>
    <p>{`In der Theorie klingt das alles großartig. Leider funktioniert es mit den meisten Browsern und Screenreadern nicht. Ich habe
eine `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/OJOMmzm"
      }}>{`Demo auf Codepen`}</a>{` zusammengestellt und die folgenden Szenarien getestet:`}</p>
    <ol>
      <li parentName="ol">{`NVDA 2021.3.1, Google Chrome 98.0.4758.82, Windows 10`}</li>
      <li parentName="ol">{`TalkBack, Firefox 97.1.0, Android 12`}</li>
      <li parentName="ol">{`VoiceOver, Safari 15, iOS 15.1`}</li>
    </ol>
    <p>{`Von den dreien leistet nur VoiceOver tolle Arbeit und liest den Inhalt gemäß dem Wert der `}<InlineCode mdxType="InlineCode">{`speak-as`}</InlineCode>{`
Eigenschaft vor. NVDA und TalkBack ignorieren es vollständig.`}</p>
    <p>{`In Anbetracht der Tatsache, dass das `}<span lang="en">{`CSS Speech Module`}</span>{` vor über 10 Jahren geschrieben wurde,
bin ich von der aktuellen Situation sehr enttäuscht. Ich hoffe, dass in Zukunft weitere Browser und Screenreader
dieses Feature unterstützen werden!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      